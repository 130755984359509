/* Component Dependencies */
var articleLinksGroupHelpTemplate = require('templates/articleLinksGroup-help.hbs');
var articleLinksGroupCopyRightTemplate = require('templates/articleLinksGroup-copyRight.hbs');
var AriesComponent = require('libs/aries-component');
var Cookies = require('libs/cookies');

AriesComponent.create({
  type: 'articleLinksGroup',
  template: {
    'articleLinksGroup-help': articleLinksGroupHelpTemplate,
    'articleLinksGroup-copyRight': articleLinksGroupCopyRightTemplate
  },
  bindEvents: function () {
    // Update session id and prod site id in global footer component
    this.$el.closest('.l-univ-nav-footer .l-container').append('<div class=\'js-session-detail m-session-details t-font-xs l-l-col-12\' aria-hidden=\'true\'>' + Cookies.readCookie('MI_SITE') + ',' + Cookies.readCookie('sessionID') + ',' + Cookies.readCookie('x-mi-tag') + '</div>');
   this.newSearchPath();
   this.getCurrentPageDetails();	
   if(window.location.pathname.indexOf("search") !== -1 || window.location.pathname.indexOf("default") !== -1){
    this.setSearchTypeDetails();
    this.setNGOPOfferDetails(); 
    this.getNGOPOfferDetails();
   }
   this.getSearchTypeDetails(); 
   setTimeout(function(){
    var segmentId = Cookies.readCookie('segmentId');
    if(segmentId != null && window.dataLayer ){
       window.dataLayer['at_segID'] = segmentId;
    }	
   },100); 
  }, 
  /**
   * set DataLayer when path is new search
   */
  newSearchPath: function() {
   var newUI = Cookies.readCookie('newSearch');
   if(newUI != null && window.dataLayer ){
	window.dataLayer['search_res_path_type'] = "New Search without Multi-Rate";
    }
  },
  getCurrentPageDetails: function() {
   if(window.dataLayer){
	window.dataLayer['page_type'] = location.hostname + location.pathname;
    }
  },
  setSearchTypeDetails: function() {
  $('.l-hsearch-find .m-button-primary').click(function(){
    var searchType;  
	var recentViewed = $('.l-recently-viewed-item.autocomplete-listitem-active a').attr('data-label');
	var recentSearch = $('.autocomplete-listitem-active a').attr('data-searchtype');
    var recommended = $('.l-find-container .l-h-field').text().trim();
	var destinationAddress = $('.autocomplete-list .autocomplete-listitem-active').text().trim();
	  if(recentViewed != undefined){
	     searchType = "Selected Recently Viewed";
	  }else if(recentSearch != undefined){
	     searchType = "Selected Recent Searches";
	  }else if(recentViewed == undefined && recentSearch == undefined && recommended != undefined 
	  && destinationAddress != undefined && recommended == destinationAddress){
	     searchType = "Selected Recommended Result";  
          }else{
	    searchType = "typed";
	}
        Cookies.createCookie('updatedSearchType', searchType);
    });  
  },
  getSearchTypeDetails: function() {
   if(window.location.pathname.indexOf("search") == -1 && window.location.pathname.indexOf("reservation") == -1){
     Cookies.eraseCookie('updatedSearchType');
   } 
   var searchType = Cookies.readCookie('updatedSearchType');
   if(searchType != null && window.dataLayer){
	  window.dataLayer['search_type'] = searchType;
    }
  },
  setNGOPOfferDetails: function () {
    var pageContext = $("[name='pageContext']").text();
  if(pageContext.indexOf("ISNGOP") != -1) {
    sessionStorage.setItem("ISNGOP", "true");
    var offerContext = JSON.parse(pageContext);
    var marrOfferId = offerContext.marrOfferId;
    var ngopClusterCode = offerContext.NGOPClusterCode;
    var theCorpCode = $('input[name=corporateCode]').val();
    if(marrOfferId != undefined && marrOfferId != ""){
      window.dataLayer.promo_special_offer_id  = marrOfferId;
      if(theCorpCode != undefined && theCorpCode != ""){
        window.dataLayer.search_cluster_code = theCorpCode;
        sessionStorage.setItem("NGOPClusterCode", theCorpCode); 
      }else if(ngopClusterCode != undefined && ngopClusterCode != ""){
        window.dataLayer.search_cluster_code = ngopClusterCode;
        sessionStorage.setItem("NGOPClusterCode", ngopClusterCode); 
      }
      sessionStorage.setItem("NGOPOfferID", marrOfferId); 
    }  
  }
  },
  getNGOPOfferDetails: function () {
    var pathName = location.pathname;
    if(pathName.indexOf("/reservation/reviewDetails") != -1){
      var ISNGOP = sessionStorage.getItem("ISNGOP");
      var roomRateTab = window.dataLayer.res_room_rate_tab;
        if(ISNGOP != undefined && ISNGOP == "true" && roomRateTab.indexOf('Special Rate') == -1){
          sessionStorage.setItem("NGOPOfferID", "");
        }
    }

    if(pathName.indexOf("/reservation/confirmation") != -1){
      var ISNGOP = sessionStorage.getItem("ISNGOP");
      if(ISNGOP != undefined && ISNGOP == "true" && sessionStorage.getItem("NGOPOfferID") != null){
        window.dataLayer.promo_special_offer_id = sessionStorage.getItem("NGOPOfferID");
        window.dataLayer.search_cluster_code = sessionStorage.getItem("NGOPClusterCode");
      }
    }
  }
});
